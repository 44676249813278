import React, { useEffect, useState } from "react";
import { Row, Col, Button, DatePicker, Skeleton, Card } from "antd";

import { Link } from "react-router-dom";

//import ReactToPdf from "react-to-pdf";

import Thank from "../../assets/images/host/thank.jpg";
import Thankyou from "../../assets/images/host/thank_img.jpg";
import axios from "axios";
import moment from "moment";

import BedRooms from "../../assets/images/bed.svg";
import BathRooms from "../../assets/images/place.svg";
import BathTab from "../../assets/images/bathTap.svg";
const { RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

function ThankYou() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [home, setHome] = useState({});

  const property_id = sessionStorage.getItem("property_id");
  const adults = sessionStorage.getItem("adults");
  const check_in = sessionStorage.getItem("check_in");
  const check_out = sessionStorage.getItem("check_out");
  const amount = sessionStorage.getItem("amount");
  const status = sessionStorage.getItem("status");


  const ref = React.createRef();


  useEffect(() => {
    fetchView();
    // eslint-disable-next-line
  }, []);

  async function fetchView() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        // token: sessionStorage.getItem("token"),
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ORIGIN}api/v1/public/listing/view_property/${property_id}`,
        config
      );
      const data = await res.data;
      setHome(data);

    } catch (error) {
      
    }
  }

  const getImg = () => {
    const imgUrl = home.featured_img
      ? home.featured_img.map((img) => img)
      : Thankyou;
    const url = imgUrl[0].img_960;
    const featured_img_url = url ? url.map((x) => x.url) : Thankyou;
    const finalUrl = featured_img_url[0] ? featured_img_url[0] : Thankyou;
  

    return finalUrl;
  };



  const total = amount / 100;

  return (
    <div className="container">
      <Row>
        <Col xs={24} lg={24} className="thank_image">
          <div className="thank_you_over">
            <img src={Thank} alt="host" />
            <h1 className="header_text1">
              {" "}
              Thank you. We hope your experience will be awesome and we can’t
              wait to host you.
            </h1>
          </div>
        </Col>
      </Row>
      <Row justify="space-between" style={{ marginTop: "2rem" }}>
        <Col xs={24} lg={12} style={{ marginTop: "2rem" }}>
          <div className="edit_left_col">
            <h3>{home.property_title}</h3>
            <p>
              {home.state}, {home.country}
            </p>
            <div className="edit_inner_col">
              <p>Check-in</p>
              <p className="edit_value">:</p>
              {/* <p>01:00 PM</p> */}

              <p>
                {home.check_in !== null &&
                  home.check_in !== undefined &&
                  home.check_in !== "" &&
                  !isNaN(Number(home.check_in)) &&
                  Number(home.check_in) >= 0 &&
                  Number(home.check_in) < 24 ? moment({ hour: Number(home.check_in) }).format("hh A") : "01:00 PM"}
              </p>

            </div>
            <div className="edit_inner_col">
              <p>Check-Out</p>
              <p className="edit_value">:</p>
              {/* <p>10:00 AM</p> */}

              <p>
                {home.check_out !== null &&
                  home.check_out !== undefined &&
                  home.check_out !== "" &&
                  !isNaN(Number(home.check_out)) &&
                  Number(home.check_out) >= 0 &&
                  Number(home.check_out) < 24
                  ? moment({ hour: Number(home.check_out) }).format("hh A") : "10:00 AM"}
              </p>

            </div>
            {/*<div className="edit_inner_col">
                        <p>Address</p>
                        <p className="edit_value">:</p>
                        <p>{home.address}</p>
            </div>*/}
            <p>
              Booking Status :{" "}
              <b
                style={{ color: `${status === "true" ? "green" : "#F39C12"}` }}
              >
                {status === "true" ? "Confirmed" : "Wating for Approval"}{" "}
              </b>
            </p>
          </div>
          <Row justify="end" style={{ marginTop: "4rem" }}>
            {/* <ReactToPdf targetRef={ref} filename="Booking.pdf">
              {({ toPdf }) => ( */}
            {/* <Button type="link" onClick={() => window.print()}>
              Print Invoice
            </Button> */}
            {/* )}
            </ReactToPdf> */}

            <Link to="/your_bookings">
              <Button className="btn-primary">View Details</Button>
            </Link>
          </Row>
        </Col>
        <Col xs={0} lg={8} className="edit_right_col">
          {" "}
          <Card
            style={{
              boxShadow: "2.5px 1.5px 3px 0 rgba(0, 0, 0, 0.16)",
            }}
            className="img_card"
            cover={
              !home.featured_img ? (
                <Skeleton active />
              ) : (
                <div>
                  {home.featured_img[0].img_960.map((x) => (
                    <div>
                      <img src={getImg()} alt="host" />
                    </div>
                  ))}
                </div>
              )
            }
          >
            <div className="payment-card">
              <div className="icons_payment">
                <Row justify="space-between">
                  <Col>
                    {" "}
                    <div className="card_img_icon">
                      <img src={BathRooms} alt="bed" />
                    </div>
                    <p className="name_icon">
                      {!home ? "" : home.type_of_listing}
                    </p>
                  </Col>
                  <Col>
                    <div className="card_img_icon">
                      <img src={BedRooms} alt="Bedrooms" />
                    </div>
                    <p className="name_icon">
                      {!home ? "" : home.num_of_bedroom} Bedrooms
                    </p>
                  </Col>
                  <Col>
                    <div className="card_img_icon">
                      <img src={BathTab} alt="bathTub" />
                    </div>
                    <p className="name_icon">
                      {!home ? "" : home.num_of_bath} Bathrooms
                    </p>
                  </Col>
                </Row>
              </div>

              <div className="checkin-details">
                <Col lg={24}>
                  <div className="date_payment">
                    <label style={{ display: "flex", marginTop: "5px" }}>
                      Check-In - Check-Out
                    </label>
                    <RangePicker
                      disabled
                      defaultValue={
                        check_in
                          ? [
                              moment(check_in, dateFormat),
                              moment(check_out, dateFormat),
                            ]
                          : ""
                      }
                      format={dateFormat}
                      size="large"
                    />
                  </div>
                </Col>
              </div>

              <div>
                <Col lg={24}>
                  <div className="guest-count">
                    <p> {adults} Guests </p>
                    {/* <p> {guest.adults} Guests </p> */}
                  </div>
                </Col>
              </div>

              <div className="total">
                <Row justify="space-between">
                  <div>
                    <h4>Total</h4>
                  </div>
                  <div className="d-flex">
                    <h4>
                      <span className="rupee"> ₹ </span> {total}
                    </h4>
                  </div>
                </Row>
                <Col>
                  <div>
                    <p style={{ fontSize: "12px" }}>Includes taxes and fee</p>
                  </div>
                </Col>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <div ref={ref} style={{ display: "none" }}>
        <Col lg={20} style={{ padding: "3rem", display: "block" }}>
          <div className="edit_left_col">
            <h3>{home.property_title}</h3>
            <p>
              {home.state}, {home.country}
            </p>
            <div className="edit_inner_col">
              <p>Check-in</p>
              <p className="edit_value">:</p>
              <p>11:00 AM</p>
            </div>
            <div className="edit_inner_col">
              <p>Check-Out</p>
              <p className="edit_value">:</p>
              <p>12:00 PM</p>
            </div>
            <div className="edit_inner_col">
              <p>Address</p>
              <p className="edit_value">:</p>
              <p>{home.address}</p>
            </div>
            <p>
              Booking Status : <b style={{ color: "green" }}>Confirmed</b>
            </p>
          </div>
        </Col>
      </div>
    </div>
  );
}

export default ThankYou;
